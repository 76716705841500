import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { ListComponent } from 'src/app/components/other-events/list/list.component';
import { Subscription } from 'rxjs';
import { FixtureService } from 'src/app/services/Fixture/fixture.service';
import { State } from 'src/app/interfaces/State/Fixture/state';
import { Store } from '@ngrx/store';
import { HelperService } from 'src/app/services/Misc/Helper/helper.service';
import { ConfigService } from 'src/app/services/Misc/Config/config.service';

@Component({
  selector: 'app-index',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    ListComponent,
  ],
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent {
  public client: string
  public isLoadingInitially: boolean = true
  public showOtherEvents: boolean

  public fixtures: State = {
    upcomingOtherEvents : undefined
  }

  private _subscription?: Subscription

  constructor(
    private _fixtureService: FixtureService,
    private _store: Store,
    private _helperService: HelperService,
    private _configService: ConfigService,
  ) {
    this.client = this._helperService.getClient()
    this.showOtherEvents = this._configService.clientConfig()?.other_events ?? false

  }

  ngOnInit(): void {
    if (!this.showOtherEvents) {
      return
    }
    this._fixtureService.getOtherEvents()

    this._subscription = this._store.subscribe((state: any) => {
      this.fixtures = state.fixtures
    })
  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe()
  }
}
