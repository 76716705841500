import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs';
import { hideMobileMenu, showMobileMenu } from 'src/app/actions/common.actions';
import { UserType } from 'src/app/enums/user/userType';
import { Auth } from 'src/app/interfaces/State/Auth/auth';
import { CommonState } from 'src/app/interfaces/State/Common/common-state';
import { Language } from 'src/app/interfaces/State/Language/language';
import { AuthService } from 'src/app/services/Auth/auth.service';
import { ConfigService } from 'src/app/services/Misc/Config/config.service';
import { HelperService } from 'src/app/services/Misc/Helper/helper.service';
import { ModalService } from 'src/app/services/Modal/modal.service';
import { NavbarService } from 'src/app/services/Navbar/navbar.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  public common: CommonState = {} as CommonState
  public auth: Auth = {} as Auth
  public availableLanguages?: Array<Language>
  public showLanguageSelector: Boolean = false
  public showFlex: Boolean = false
  public client: String
  public showOtherEvents: Boolean = false
  @ViewChild('languageSelector') languageSelectorRef!: ElementRef;

  constructor(
    private _router: Router,
    private _store: Store,
    private _navbarService: NavbarService,
    private _authService: AuthService,
    private _modalService: ModalService,
    private _translateService: TranslateService,
    private _configService: ConfigService,
    private _helperService: HelperService,
    private _localizeService: LocalizeRouterService,
    private _eref: ElementRef,
    private _activatedRoute: ActivatedRoute,
  ) {
    _router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this._navbarService.hideMobileMenu()
      }
    });

    this.showFlex = this._configService.clientConfig()?.flex ?? false
    this.showOtherEvents = this._configService.clientConfig()?.other_events ?? false
    this.client = this._helperService.getClient()
  }

  ngOnInit(): void {
    this.getAvailableLanguages()

    this._store.subscribe((state: any) => {
      this.common = state.common
      this.auth = state.auth
    });

  }

  @HostListener('document:click', ['$event'])
  onClick(event: Event) {
    const clickedElement = event.target as HTMLElement;
    const isInsideComponent = this.languageSelectorRef.nativeElement.contains(event.target);

    if (!isInsideComponent) {
      this.showLanguageSelector = false;
    }
  }

  toggleMenu() {
    if (this.common.showMobileMenu) {
      this._navbarService.hideMobileMenu()
    } else {
      this._navbarService.showMobileMenu()
    }
  }

  invoiceDetails() {
    this._navbarService.hideMobileMenu()
    this._modalService.showInvoiceModal()
  }

  hideProfileMenu() {
    this._navbarService.hideProfileMenu()
  }

  logout() {
    this._authService.logout()
  }

  isCashier() {
    return this.auth.isUserLoggedIn && this.auth.user.type == UserType.CASHIER
  }

  showResetPasswordModal() {
    this._modalService.showLoginModal()
  }

  isUserLoggedIn() {
    return this.auth.isUserLoggedIn && this.auth.user.type
  }

  getAvailableLanguages() {
    this._configService.loadClientConfiguration(this._helperService.getClient(), this._helperService.getEnvironment())
      .then(config => {
        // Use the loaded configuration here
        this.availableLanguages = config.languages ?? []
      }).catch(error => {
        console.error('Error loading configuration:', error);
      });
  }

  setLanguage(language: string) {
    /* if route has no localized segment */
    if (!this._activatedRoute.snapshot.firstChild?.data?.['hasLocalizedSegment'] || !this._activatedRoute.snapshot.firstChild?.data?.['localizedSegments']) {
      this._translateService.use(language)
      this._localizeService.changeLanguage(language)
      this.showLanguageSelector = false
      return
    }

    /* localized segments */
    const localizedSegments = this._activatedRoute.snapshot.firstChild?.data?.['localizedSegments']
    let localizedPath = this._activatedRoute.snapshot.firstChild?.data?.['localizedPath']

    /* get state */
    this._store.pipe(take(1))
      .subscribe((state: any) => {

        /* translate route */
        this._localizeService.changeLanguage(language)
        localizedPath = this._helperService.translateRoute(localizedPath);

        /* localized segments */
        Object.keys(localizedSegments).map((index) => {
          const localizedSegment = localizedSegments[index]

          const data = localizedSegment['data']
          const segment = localizedSegment['segment']

          const keys = data.split('.')

          let val: any = null

          Object.keys(keys).map(index => {
            const key = keys[index]
            val = val ? val[key] : state[key]
          })

          val = val[language] ?? val['en']

          localizedPath = localizedPath.replace(segment, val)
        })

        localizedPath = localizedPath.split('/')

        /* other segments */
        this._activatedRoute.firstChild?.paramMap.pipe(take(1)).subscribe(params => {
          localizedPath = localizedPath.map((item: string) => {
            if (item.includes(':')) {
              return params.get(item.replace(':',''));
            }
            return item;
          }).join('/')

          this._router.navigate([localizedPath])
        });
        
      })

  }

  isCurrentLanguage(language: string): boolean {
    return this._translateService.currentLang == language
  }

  onOutsideClick(event: Event) {
    console.log('Clicked outside:', event);
    // Handle the outside click event
  }

}
