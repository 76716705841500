import { createReducer, on } from "@ngrx/store";
import { setOpenedFixture, setUpcomingFixtures, setOtherEvents } from "../actions/fixture.actions";
import { Index } from "../interfaces/State/Fixture";
import { State } from "../interfaces/State/Fixture/state";

export const initialState = {
    upcomingFixtures: undefined,
    openedFixture:{} as Index,
    upcomingOtherEvents: undefined,
} as State

export const FixtureReducer = createReducer(
    initialState,
    on(setUpcomingFixtures, (state, {fixtures}) => ({...state, upcomingFixtures : fixtures})),
    on(setOpenedFixture, (state, {fixture}) => ({...state, openedFixture : fixture})),
    on(setOtherEvents, (state, {otherEvents}) => ({...state, upcomingOtherEvents : otherEvents})),
);