<!-- LOADER -->
<ul id="fixtures" class="bg-white rounded-[20px] overflow-hidden" *ngIf="otherEvents == undefined">
    <li *ngFor="let loader of [].constructor(5); let i = index;" >
        <app-skeleton-loader [row]="3.875" />
    </li>
</ul>

<!-- EMPTY STATE -->
<p class="text-white" *ngIf="!otherEvents?.length && otherEvents !== undefined && needEmptyText">{{ 'other-events.no-result' | translate }}</p>

<!-- OTHER EVENTS-->
<ul class="bg-white rounded-[20px]" *ngIf="otherEvents?.length">
    <app-single-other-event *ngFor="let otherEvent of otherEvents" [otherEvent]="otherEvent"></app-single-other-event>
</ul>
