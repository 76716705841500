<div class="p-0 flex gap-6 sm:gap-0 sm:flex-col sm:flex text-white sm:relative">
    <button class="sm:absolute sm:top-[8px]" (click)="navigateBack()" type="button" *ngIf="!mapOnly">
        <app-back-icon ></app-back-icon>
    </button>

    <!-- OPENED FIXTURE -->
    <ng-container *ngIf="openedFixture.id">
        <div class="flex flex-col justify-center sm:text-center sm:mb-4 sm:border-b sm:pb-4 sm:border-[#3b3a3a]" *ngIf="!mapOnly && !isFixtureTypes(openedFixture, fixtureTypes().OTHER)">
            <p class="text-[15px] font-black uppercase truncate sm:max-w-[80%] sm:mx-auto">{{ openedFixture.competition.name }}</p>
            <p class="text-sm">{{ openedFixture.date }}</p>
        </div>
        <!-- FIXTURE -->
        <div class="flex justify-between items-center" *ngIf="isFixtureTypes(openedFixture, fixtureTypes().MATCH)">
            <img class="h-[40px]" [src]="openedFixture.home_team.logo" [alt]="openedFixture.home_team.name"/>
            <div class="px-[10px] sm:px-[4px] sm:max-w-[75%]">
                <p class="text-[15px] font-black text-center truncate px-1">{{ openedFixture.home_team.name }} - {{ openedFixture.away_team.name }} </p>
                <p class="text-sm text-center truncate px-1">{{ openedFixture.venue }}</p>
            </div>
            <img class="h-[40px]" [src]="openedFixture.away_team.logo" [alt]="openedFixture.away_team.name"/>
        </div>

        <!-- OTHER EVENT -->
        <div class="flex justify-between items-center" *ngIf="isFixtureTypes(openedFixture, fixtureTypes().OTHER)">
            <img class="h-[40px]" *ngIf="openedFixture.image" [src]="openedFixture.image" [alt]="openedFixture.name"/>
            <div class="px-[10px] sm:px-[4px] sm:max-w-[75%] ml-1">
                <p class="text-[15px] font-black text-left truncate">{{ openedFixture.name }} </p>
                <p class="text-sm text-left truncate">{{ openedFixture.venue }} - {{ openedFixture?.date }}</p>
            </div>
        </div>
    </ng-container>
     <!-- OPENED FIXTURE -->

    <!-- OPENED SEASONN PASS -->
    <ng-container *ngIf="openedSeasonPass.slug">
        <div class="flex justify-center items-center ">
            <div class="px-[10px] sm:px-[4px] sm:max-w-[75%]">
                <p class="text-[15px] font-black text-left truncate px-1">{{ openedSeasonPass.name }}</p>
                <p class="text-sm text-left truncate px-1">{{ openedSeasonPass.subtitle }}</p>
            </div>
        </div>
    </ng-container>

    <!-- OPENED FLEX -->
    <ng-container *ngIf="openedFlex.uuid">
        <div class="flex justify-between items-center">
            <div class="px-[10px] sm:px-[4px] sm:max-w-[75%] ml-1">
                <p class="text-[15px] font-black text-left truncate">{{ openedFlex.title }} </p>
                <p class="text-sm text-left truncate">{{ openedFlex.description }}</p>
            </div>
        </div>
    </ng-container>
    <!-- OPENED FLEX -->
</div>