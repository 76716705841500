import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Index } from 'src/app/interfaces/State/Fixture';
import { ButtonModule } from 'src/app/components/form/button/button.module';
import { LinkButtonModule } from 'src/app/components/common/link-button/link-button.module';
import { TranslateModule } from '@ngx-translate/core';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';

@Component({
  selector: 'app-single-other-event',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    LinkButtonModule,
    TranslateModule,
    LocalizeRouterModule,
  ],
  templateUrl: './single-other-event.component.html',
  styleUrls: ['./single-other-event.component.scss']
})
export class SingleOtherEventComponent {
  @Input() otherEvent: Index = {} as Index
  public open: boolean = false

  public toggle() {
    this.open = !this.open
  }
}
