<li>
    <div class="p-10 grid from-lg:grid-cols-[74%_26%]  md:grid-cols-[60%_40%] lg:grid-cols-[70%_30%] sm-md:flex sm-md:flex-col">
        <div class="flex gap-6 items-center">
            <img class="w-[100px] rounded-[10px]" *ngIf="seasonPass.logo" [src]="seasonPass.logo" [alt]="seasonPass.name" />
            <div>
                <p class="text-[15px] font-black text-left">{{ seasonPass.name }} </p>
                <p class="text-[13px] text-left ">{{ seasonPass.subtitle }}</p>
            </div>
        </div>
        <div class="flex gap-3 justify-end sm-md:flex-col sm-md:gap-3 sm-md:mt-4">
            <div class="flex end justify-end items-center sm-md:flex-col">
                <app-button class="sm-md:w-full" (click)="toggle()" [style]="'details'">{{ "single-season-pass.details" | translate }}</app-button>
            </div>
            <div class="flex end justify-end items-center sm-md:flex-col">
                <app-link-button 
                    class="sm-md:w-full" 
                    cssClasses="sm-md:w-full sm-md:text-center"
                    [link]="['/season-pass', seasonPass.slug] | localize"
                    >
                    {{ "single-season-pass.purchase" | translate }}
                </app-link-button>
            </div>
        </div>
    </div>
    <div 
        class="p-10 pt-0 text-[14px] sm-md:-mt-4" 
        [innerHTML]="seasonPass.description" 
        *ngIf="open"
    ></div>
</li>