import { Component, Input, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Index } from 'src/app/interfaces/State/Fixture';
import { LinkButtonModule } from '../../common/link-button/link-button.module';
import { ButtonModule } from '../../form/button/button.module';
import { SkeletonLoaderComponent } from '../../skeleton-loader/skeleton-loader.component';
import { TranslateModule } from '@ngx-translate/core';
import { SingleOtherEventComponent } from './_partials/single-other-event/single-other-event.component';

@Component({
  selector: 'app-other-events-list',
  standalone: true,
  imports: [
    CommonModule,
    LinkButtonModule,
    ButtonModule,
    SkeletonLoaderComponent,
    TranslateModule,
    SingleOtherEventComponent,
  ],
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent {
  @Input() otherEvents?: any
  @Input() needEmptyText: Boolean = true

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  toggle() {

  }
}
